<template>
  <el-container class="index-box">
    <el-aside width="asideWidth">
      <div class="logo">
        <img src="./../assets/logo.png" alt="ELementUI" width="40" height="40">
        <div style="margin-left: 10px" v-if="!isCollapse">中视华章</div>
      </div>
      <menus :isCollapse="isCollapse"/>
    </el-aside>
    <el-container>
      <el-header>
        <headers/>
      </el-header>
      <el-main>
        <router-view v-if="isRouterAlive"/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import Menus from '@/components/menus/menus'
  import Headers from '@/components/header/header'

  export default {
    provide () {
      return {
        reload: this.reload
      }
    },
    data () {
      return {
        isRouterAlive: true
      }
    },
    components: {
      Menus,
      Headers
    },
    methods: {
      reload () {
        this.isRouterAlive = false
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      }
    },
    computed: {
      isCollapse () {
        return this.$store.state.isCollapse
      },
      asideWidth () {
        return this.$store.state.isCollapse ? 'auto' : '200px'
      }
    }
  }
</script>

<style lang="scss">
  .index-box {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;

    .el-container {
      height: 100%;

      .el-main {
        height: 100%;
      }
    }

    .el-aside {
      background-color: #20222A;
      height: 100vh;
      min-height: 100%;

      .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #20222A;
        color: rgba(255, 255, 255, .8);
        text-align: center;
        height: 60px;
        font-size: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid gray;
      }
    }

    .el-header {
      border-bottom: 1px solid #f6f6f6;
      box-sizing: border-box;
      background-color: #fff;
      line-height: 60px;
    }
  }
</style>
