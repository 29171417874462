<template>
  <div class="container">
    <el-form :model="edit_file" ref="infoForm" label-width="100px">
      <el-form-item label="文件名" prop="name" :rules="[ {required: true, message: '请填写文件名', trigger: 'blur'}]">
        <div style="width: 200px">
          <el-input v-model="edit_file.name"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="文件">
        <el-upload
          class="upload"
          action=""
          drag
          ref="upload"
          :limit="1"
          :http-request="uploadFile"
          :show-file-list="true"
          :auto-upload="false">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </el-form-item>
    </el-form>
    <div style="width: 200px; margin-left: 100px">
      <el-button type="primary" @click="submit('infoForm')">确 定</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        edit_file: { name: '' }
      }
    },
    mounted () {
    },
    methods: {
      submit (formName) {
        // 表单验证
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$refs.upload.submit()
          } else {
            return false
          }
        })
      },
      uploadFile (files) {
        console.log('files:', files.file)
        const loading = this.$loading({
          lock: true,
          text: '提交中。。。',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$api.uploadFile({
          file: files.file,
          name: this.edit_file.name
        }).then(res => {
          loading.close()
          this.$message({ message: '上传成功', type: 'success' })
        }).catch(() => {
          loading.close()
        })
      }
    }
  }
</script>

<style>
  .container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
</style>
