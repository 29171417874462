<template>
  <div class="console">
    <img src="./../assets/logo.png" alt="ELementUI" width="200" height="200">
    <h1 class="mdui-m-b-4 mdui-p-t-5 mdui-text-color-grey-700">欢迎使用中视华章网站管理后台</h1>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .console {
    font-size: 30px;
    text-align: center;
    margin-top: 200px;
  }
</style>
